import React, {useEffect} from 'react';

const About = () => {
    useEffect(() => {
        window.location.href = '/#Services'
    }, []);

    return null;
};

export default About;
